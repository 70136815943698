<template>
    <Breadcrumb v-if='breadcrumbs && breadcrumbs.length > 0' :home='home' :model='allCrumbs' />

    <div v-if='steps && steps.length > 0' class='card' style='margin-bottom: 0'>
        <Steps :model='steps' :readonly='false'></Steps>
    </div>
</template>
<script>

export default {
    props: {
        home: {
            type: String,
            default: '',
        },
        breadcrumbs: {
            type: Array,
            required: true,
        },
        steps: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        allCrumbs() {
            const homeCrumb = {
                label: 'Menu Principal',
                to: this.home,
            };
            return [homeCrumb, ...this.breadcrumbs];
        },
    },
};
</script>
