<template>
    <div style='display: flex;flex-direction: column;gap: 10px;'>
        <Header :breadcrumbs='breadcrumbs' :steps='steps' home='/mapaparceiros/exportacaoxls' />

        <div class='card'>
            <slot />
        </div>
    </div>
</template>

<script>
import Header from '../../layout/Header.vue';

export default {
    components: {
        Header,
    },
    data() {
        return {
            breadcrumbs: [
                { label: 'Menu principal', to: '/mapaparceiros/exportacaoxls' },
                { label: 'Listagem de Acordos', to: '/mapaparceiros/parceria' },
                { label: 'Formulário de parceria', to: `/mapaparceiros/parceria/form/geral/${this.$route.params.id}` },
            ],
            steps: [
                {
                    label: 'Dados Gerais',
                    to: `/mapaparceiros/parceria/form/geral/${this.$route.params.id}`,
                },
                {
                    label: 'Cláusulas',
                    disabled: () => !this.$route.params.id,
                    to: `/mapaparceiros/parceria/form/clausulas/${this.$route.params.id}`,
                },
                {
                    label: 'Local e LGPD',
                    disabled: () => !this.$route.params.id,
                    to: `/mapaparceiros/parceria/form/dados-contrato/${this.$route.params.id}`,
                },
                {
                    label: 'Valores e Perfis',
                    disabled: () => !this.$route.params.id,
                    to: `/mapaparceiros/parceria/form/valores-perfis/${this.$route.params.id}`,
                },
            ],
        };
    },
};

</script>