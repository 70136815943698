<template>
    <LayoutEtapas>

        <div class='formgrid grid p-fluid'>
            <div class='field col-12'>
                <label>Objeto da Parceria</label>
                <Editor ref='editorObjeto' v-model='objeto' :readonly='desabilita' editorStyle='height: 220px' />
            </div>
        </div>

        <div class='formgrid grid p-fluid'>
            <div class='field col-6'>
                <label>Compete ao Sebrae</label>
                <Editor ref='editorCompeteSebrae' v-model='competeSebrae' :readonly='desabilita' editorStyle='height: 220px'
                        rows='5' type='text' />
            </div>
            <div class='field col-6'>
                <label>Compete ao Parceiro</label>
                <Editor ref='editorCompeteParceiro' v-model='competeParceiro' :readonly='desabilita' editorStyle='height: 220px'
                        rows='5' type='text' />
            </div>
        </div>

        <div class='formgrid grid p-fluid'>
            <div class='field col-12'>
                <label>Cláusulas da Parceria</label>
                <Editor ref='editorClausulas' v-model='clausulas' editorStyle='height: 320px' readonly />
            </div>
        </div>

        <div class='flex flex-wrap gap-3'>
            <div class='flex-1'></div>
            <Button style='margin-right: 10px' @click='$router.back()'>Voltar</Button>
            <Button :disabled='v$.$invalid' @click='onSubmit()'>Continuar</Button>
        </div>
    </LayoutEtapas>
</template>

<script>
import LayoutEtapas from './LayoutEtapas.vue';
import Services from '../../../service';
import Editor from 'primevue/editor';
import { useVuelidate } from '@vuelidate/core';

export default {
    components: {
        LayoutEtapas,
        Editor,
    },
    setup() {
        return {
            v$: useVuelidate({}),
        };
    },
    validations() {
        return {
            objeto: {},
            clausulas: {},
            competeSebrae: {},
            competeParceiro: {},
        };
    },
    data() {
        return {
            desabilita: false,
            objeto: '',
            clausulas: '',
            competeSebrae: '',
            competeParceiro: '',
            idAbrangencia: null,
            // idModalidadeApoio: null,
            listaAbrangencia: [
                {
                    id: 1,
                    label: 'Local',
                },
                {
                    id: 2,
                    label: 'Regional',
                },
                {
                    id: 3,
                    label: 'Estadual',
                },
                {
                    id: 4,
                    label: 'Aberta',
                },
            ],
            // listaModalidadeApoio: [],
        };
    },
    methods: {
        onSubmit() {
            this.$store.dispatch('addRequest');
            const data = {
                objeto: this.objeto,
                competeSebrae: this.competeSebrae,
                competeParceiro: this.competeParceiro,
            };
            Services.AlterarAcordoDeParceriaClausulas(this.$route.params.id, data)
                .then((response) => {
                    if (response && response.success) {
                        this.$router.push({
                            name: 'MapaParceiros_Parceria_Formulario_DadosContrato',
                            params: {
                                id: this.$route.params.id,
                            },
                        });
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },
        carregaDados() {
            const id = this.$route.params.id;
            this.$store.dispatch('addRequest');
            Services.SelectByIdParcerias(id)
                .then((response) => {
                    if (response && response.success && response.data) {
                        this.objeto = response.data.objeto;
                        this.competeParceiro = response.data.competeParceiro;
                        this.competeSebrae = response.data.competeSebrae;
                        this.clausulas = response.data.clausulas;
                        this.desabilita = response.data.status !== 0 && response.data.status !== 1;
                    }
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },
    },
    mounted() {
        if (!this.$route.params.id) {
            this.$router.replace({
                name: 'MapaParceiros_Parceria_Formulario_DadosGerais',
            });
        } else {
            this.carregaDados();
        }
    },
};

</script>